@import '../../constants/ColorsStyles';

.root {
  transition: all 0.6s ease;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  max-width: 70vw;
  background: var(#{--components-background-default});
  box-shadow: 2px 5px 25px -3px rgba(180, 180, 180, 0.25);
  border-radius: 20px;
  background: $backgroundFooter;
  z-index: 1500;
}

.blur {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  //width: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  z-index: 900;
  align-items: center;
  justify-content: center;
}
