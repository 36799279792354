@import "../../constants/ColorsStyles";

.root_file {
    margin-top: 5px;
    width: 100%;
    height: 46px;
    border-radius: 12px;
    aligin-items: center;
    display: flex;
    background-color: #fff;
}

.item_header_label {
    // margin-left: 20px;
    font-size: 17px;
    color: #fff;
}

.wrapper_bool {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.root_click {
    width: 50%;
}

.root_click_b {
    margin-top: 0px;
    width: 100%;
    aligin-items: center;
    display: flex;
    justify-content: space-between;
    //padding: 0 10px;
}

.block_wrapper_b {
    width: 50%;
}

.wrapper_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.block_root {
    width: 50%;
}

.button_input {
    display: flex;
    aligin-items: center;
    align-content: center;
    cursor: pointer;
    margin-top: 10px;
}

.button_input_b {
    aligin-items: center;
    display: flex;
    cursor: pointer;
    margin-top: 10px;
}

.box {
    border: 1px solid $colorButton;
    width: 18px;
    height: 18px;
    background-color: #e0e6f1;
}

.box_active {
    border: 2px solid $colorButton;
    width: 18px;
    height: 18px;
    background-color: $colorButton;
    box-shadow: 2px 5px 25px 2px rgba(112, 146, 190, 0.4);
}

.clip {
    border: 1px solid $colorButton;
    min-width: 18px;
    height: 18px;
    border-radius: 100px;
    background-color: #e0e6f1;
    //box-shadow: 2px 2px 25px 2px rgba(180, 180, 180, 0.6);
    //box-shadow: 2px 5px 25px 2px rgba(112, 146, 190, 0.4);
}

.clip_active {
    border: 2px solid $colorButton;
    min-width: 18px;
    height: 18px;
    border-radius: 100px;
    background-color: $colorButton;
    box-shadow: 2px 5px 25px 2px rgba(112, 146, 190, 0.4);
}

.clip_text {
    margin-top: 2px;
    margin-left: 10px;
    font-size: 14px;
    color: #e0e6f1;
}

.open_input {
    width: 100%;
    display: block;
    padding: 0 18px;
    font-size: 0.98rem;
    font-weight: 400;
    line-height: 5;
    color: #212529;
    background-clip: padding-box;
    background-color: #e0e6f1;
    border: 0;
    outline: none;
    border-radius: 14px 0 0 14px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input_file {
    width: 100%;
    display: block;
    padding: 0 18px;
    font-size: 0.98rem;
    font-weight: 400;
    line-height: 5;
    color: #212529;
    background-clip: padding-box;
    background-color: #e0e6f1;
    border: 0;
    outline: none;
    border-radius: 14px 0 0 14px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input_file::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a7a7a7;
    font-weight: 300;
}
.input_file::-moz-placeholder {
    /* Firefox 19+ */
    color: #a7a7a7;
    font-weight: 300;
}
.input_file:-ms-input-placeholder {
    /* IE 10+ */
    color: #a7a7a7;
    font-weight: 300;
}
.input_file:-moz-placeholder {
    /* Firefox 18- */
    color: #a7a7a7;
    font-weight: 300;
}

.button_file {
    border-left: 1px solid #c4c4c4;
    width: 65px;
    height: 100%;
    border-radius: 0 10px 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #e0e6f1;
    &:hover {
        opacity: 0.5;
    }
}

.step {
    opacity: 0;
    ////background-image: url("../../assets/images/upload.svg");
    position: absolute;
    //z-index: -1;
    width: 60px;
    height: 50px;
}

.root_popup {
    width: 350px;
}

.popup_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    aligin-items: center;
    margin-bottom: 20px;
}

.popup_label {
    color: #fff;
    font-size: 24px;
}

.button_close {
    margin-top: 6px;
    cursor: pointer;
    svg {
        transition: 0.3s;
    }
    &:hover {
        svg {
            transform: rotate(360deg);
        }
    }
}

.placeholder {
    margin-top: 20px;
    font-size: 12px;
    color: #fff;
    margin-left: 10px;
}

.popup_error {
    color: $colorTextError;
    font-size: 12px;
    margin-top: 10px;
}

.popup_ok {
    color: #15f539;
    font-size: 12px;
    margin-top: 10px;
}

.inputarrea {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 5px;
    display: block;
    height: 90px;
    padding: 0.375rem 22px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #e0e6f1;
    background-clip: padding-box;
    border: 0;
    outline: none;
    border-radius: 14px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 5px;
    display: block;
    height: 44px;
    padding: 0.375rem 22px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #e0e6f1;
    background-clip: padding-box;
    border: 0;
    outline: none;
    border-radius: 14px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a7a7a7;
    font-weight: 300;
}
.input::-moz-placeholder {
    /* Firefox 19+ */
    color: #a7a7a7;
    font-weight: 300;
}
.input:-ms-input-placeholder {
    /* IE 10+ */
    color: #a7a7a7;
    font-weight: 300;
}
.input:-moz-placeholder {
    /* Firefox 18- */
    color: #a7a7a7;
    font-weight: 300;
}

.popup_liner_button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px;
}

.popup_button_ok {
    width: 68%;
    height: 46px;
    background-color: $colorButton;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
        //background-color: #448FE4;
    }
}

.popup_button_exit {
    width: 30%;
    height: 46px;
    border: 2px solid $colorButton;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        border: 3px solid $colorButton;
        opacity: 0.8;
    }
}

.popup_button_delete {
    width: 90px;
    height: 26px;
    margin-right: 10px;
    border: 2px solid #ff0000;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        border: 3px solid #ff0000;
        opacity: 0.8;
    }
}

.block_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.popup_button_ok_text {
    width: 100%;
    text-align: center;
    color: #fff;
}

.popup_button_exit_text {
    width: 100%;
    text-align: center;
    color: #fff;
    //font-size: 14px;
}

.block_loader {
    display: flex;
    width: 100%;
    height: 46px;
    flex-direction: row;
    aligin-items: center;
    justify-content: center;
}

.loader {
    width: 32px;
    height: 32px;
}

.root {
    position: fixed;
    width: 100vw;
    background: $backgroundFooter;
    height: 58px;
    align-items: center;
    padding-left: 45px;
    padding-right: 45px;
    display: flex;
    justify-content: space-between;
}

.blur {
    position: absolute;
    z-index: 500;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
}

.header {
}

.block {
    height: 100%;
    display: flex;
    align-items: center;
}

.label {
    margin-left: 20px;
    font-size: 26px;
    color: #fff;
}

.menu {
    display: flex;
    margin-left: 100px;
    margin-top: 5px;
}

.button_item {
    color: #fff;
    text-decoration: none;
    margin-right: 35px;
    &:hover {
        opacity: 0.8;
    }
}

.button_item_active {
    color: #69a4e7;
    text-decoration: none;
    margin-right: 35px;
    letter-spacing: 0.07em;
}

.item_label {
}

.block_profile_active {
    position: absolute;
    top: 10px;
    right: 45px;
    z-index: 1100;
    min-width: 200px;
    border-radius: 16px;
    background-color: #fff;
    padding: 2px 2px 2px 0;
}

.block_profile_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding-left: 10px;
}

.email {
    color: #69a4e7;
    letter-spacing: 0.06em;
    margin-right: 25px;
    margin-top: 5px;
    margin-left: 10px;
}

.profile {
    border-radius: 100%;
    width: 36px;
    height: 36px;
    background-color: #c4c4c4;
    border: 2px solid #69a4e7;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        opacity: 0.85;
    }
}

.list_button {
    width: 100%;
    padding-top: 5px;
}

.button_profile_item {
    padding: 5px 10px 5px 10px;
    margin-top: 4px;
    display: flex;
    width: 100%;
    cursor: pointer;
    &:hover {
        background: rgba(0, 0, 0, 0.03);
    }
}

.button_profile_item_text {
    font-size: 16px;
    width: 100%;
    text-align: right;
}

.label_profile {
    font-size: 28px;
    color: #fff;
}

.liner_menu {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.liner_menu_item {
    color: #fff;
    margin-right: 12px;
    font-size: 14px;
    cursor: pointer;
}

.liner_menu_item_active {
    text-decoration: underline;
}

.items {
}

.button_upload {
    width: 100%;
    height: 36px;
    //background-color: $colorButton;
    color: #e0e6f1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
}

.button_upload_text {
    color: #e0e6f1;
}
