@import "../../constants/ColorsStyles";

.main {
    position: absolute;
    top: 5%;
    bottom: 5%;
    left: 0;
    right: 0;
    height: 90vh;
    margin: 0 auto;
    z-index: -1;
    // background-image: url(../../assets/images/background-logo.png);
}

.mainG {
    position: absolute;
    top: -70px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    // background-image: url(../../assets/images/Logo_backround.png);
    background-size: cover;
    text-align: right;
    display: block;
    margin-left: auto;
}

.inp {
    border-color: $auhtBorderInputColor;
    border-radius: $auhtRadiusInputColor;
}

.but {
    border-radius: $auhtRadiusInputColor;
}

.top {
    height: 50px;
}

.top_input {
    height: 10px;
}

.footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.button_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
}

.button_footer_text {
    font-size: 15px;
    color: #fff;
}

.root {
    z-index: 20;
    width: 480px;
    margin: 0 auto;
    margin-top: 10vh;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 40px;
}

@media (max-width: 680px) {
    .root {
        width: 100vw;
        padding: 0 40px 0 40px;
    }
}

.block {
    width: 80%;
}

.text_foot {
    margin-top: 30%;
    font-size: 16px;
    margin-bottom: 10px;
    color: #fff;
}
