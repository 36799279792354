@import "../../constants/ColorsStyles";

.table {
    width: 100%;
}

.tooltip_test {
    position: relative;
    width: 100%;
    cursor: pointer;

    &:hover {
        .tooltip {
            display: block;
        }
    }
}

.tooltip_course {
    position: relative;
    width: 100%;
    cursor: pointer;

    &:hover {
        .tooltip {
            display: block;
        }
    }
}

.tooltip {
    display: none;
    position: absolute;
    top: 20px;
    background-color: #ffffff;
    border-radius: 6px;
    padding: 4px 6px;
    z-index: 3000;
}

.text_item {
    font-size: 10px;
}

.icon_filter {
    opacity: 0.4;
}

.icon_filter_active {
    opacity: 1;
}

.table_tr {
    width: 100%;
    height: 30px;
}

.table_td {
    height: 100%;
    padding: 0 6px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
}

.table_td_block {
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.table_td_label {
    font-size: 13px;
    margin-right: 5px;
}

.loader {
    margin: 0 auto;
    width: 40px;
    height: 40px;
}

.td_error_text {
    font-size: 15px;
    margin: 3px 0;
}

.tr_error {
    width: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

.td_error {
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

.td_error_block {
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgba(112, 146, 190, 0.6);
}

.wrapper_buttons {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.default_reordering {
    width: 26px;
    height: 20px;
}

.button_reordering {
    display: none;
    width: 26px;
    height: 20px;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    opacity: 0.6;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

.tr_br {
    width: 100%;
    height: 30px;
    background-color: $backgroundTwo;
    cursor: pointer;
    &:hover {
        background-color: $backgroundTwoHover;

        // добавить отображение reordering
        .button_reordering {
            display: flex;
        }
    }
}

.tr {
    width: 100%;
    height: 30px;
    cursor: pointer;
    &:hover {
        background-color: rgba(112, 146, 190, 0.1);

        // добавить отображение reordering
        .button_reordering {
            display: flex;
        }
    }
}

.td {
    height: 100%;
    align-items: center;
    font-size: 14px;
    padding: 0 6px;
}
