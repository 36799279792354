@import "../../constants/ColorsStyles";

.root {
    width: 350px;
    height: 38px;
    border-radius: 12px;
    border: 2px solid $SettingColorInput;
    aligin-items: center;
    display: flex;
    margin-right: 15px;
}

.input {
    width: 100%;
    display: block;
    padding: 0 18px;
    font-size: 0.98rem;
    font-weight: 400;
    line-height: 5;
    color: #212529;
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    outline: none;
    border-radius: 14px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $SetingColorTextFilter;
    font-weight: 300;
}
.input::-moz-placeholder {
    /* Firefox 19+ */
    color: $SetingColorTextFilter;
    font-weight: 300;
}
.input:-ms-input-placeholder {
    /* IE 10+ */
    color: $SetingColorTextFilter;
    font-weight: 300;
}
.input:-moz-placeholder {
    /* Firefox 18- */
    color: $SetingColorTextFilter;
    font-weight: 300;
}

.button {
    width: 65px;
    height: 100%;
    border-radius: 0 10px 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
}
