@import url("https://fonts.googleapis.com/css2?family=Bellota:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap");

*,
*:after,
*:before {
    box-sizing: border-box;
}

body {
    margin: 0;
    background-image: url("./background.jpg");
}

input {
    outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #fff;
    font-weight: 300;
    opacity: 0.8;
}
::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
    font-weight: 300;
    opacity: 0.8;
}
:-ms-input-placeholder {
    /* IE 10+ */
    color: #fff;
    font-weight: 300;
    opacity: 0.8;
}
:-moz-placeholder {
    /* Firefox 18- */
    color: #fff;
    font-weight: 300;
    opacity: 0.8;
}
