@import url('https://fonts.googleapis.com/css2?family=Bellota:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap');


.CustomFontLite {
    //font-size: 18px;
    font-family: 'OpenSans', sans-serif;
    font-weight: 300;
    //color: #fff;
}

.CustomFontRegular {
    //font-size: 18px;
    font-family: 'OpenSans', sans-serif;
    font-weight: 400;
    //color: #fff;
}
.CustomFontMedium {
    //font-size: 18px;
    font-family: 'OpenSans', sans-serif;
    font-weight: 500;
    //color: #fff;
}

.CustomFontBold {
    //font-size: 18px;
    font-family: 'OpenSans', sans-serif;
    font-weight: 600;
    //color: #fff;
}

.BellotaFontLite {
    //font-size: 18px;
    font-family: 'Bellota', sans-serif;
    font-weight: 300;
    //color: #fff;
}

.BellotaFontRegular {
    //font-size: 18px;
    font-family: 'Bellota', sans-serif;
    font-weight: 400;
    //color: #fff;
}

.BellotaFontBold {
    //font-size: 18px;
    font-family: 'Bellota', serif;
    font-weight: 500;
    //color: #fff;
}
