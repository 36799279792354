$SettingBackgroundFooter: #021f2d;
$SettingColorHr: #b3b3b3;
$SettingColorButton: #7092be;
$SettingColorInput: #7092be;
$SettingBackground: #fff;
$SettingBackgroundTwo: rgba(112, 146, 190, 0.6);
$SettingBackgroundTwoHover: rgba(112, 146, 190, 0.45);
$SettingColorTextError: #fcb900;
$SettingBackgroundInput: #e0e6f1;
$SettingBorderInput: 0;
$SetingColorTextInput: #212529;
$SetingColorTextFilter: #7e7e7e;

$SetingAuthBorderInputColor: 2px solid #7092be;
$SetingAuhtRadiusInputColor: 14px;
