.main {
    position: absolute;
    top: 5%;
    bottom: 5%;
    left: 0;
    right: 0;
    height: 90vh;
    margin: 0 auto;
    z-index: -1;
}
