.label {
    margin-left: 20px;
    font-size: 26px;
    color: #fff;
}

.logo_app {
    display: flex;
    align-items: center;
}
