@import "../../constants/ColorsStyles";

.button {
    width: 100%;
    height: 46px;
    background-color: $colorButton;
    // border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    border-radius: $auhtRadiusInputColor;
}

.button_text {
    width: 100%;
    text-align: center;
    color: #fff;
}

.block_loader {
    display: flex;
    width: 100%;
    height: 46px;
    flex-direction: row;
    aligin-items: center;
    justify-content: center;
}

.loader {
    margin-top: 3px;
    width: 32px;
    height: 32px;
}
